.table-container {
    overflow-x: auto;
    margin: 0 -15px 0 5px;
}

.case-tracking-column {
    background-color: lightblue !important;
    text-align: center;
}

.post-sales-column {
    background-color: lightgreen !important;
    text-align: center;
}

.expedient-column {
    background-color: lightyellow !important;
    text-align: center;
    position: sticky;
    left: 0;
    z-index: 1;
}

.case-tracking-header {
    background-color: lightblue !important;
    text-align: center;
}

.post-sales-header {
    background-color: lightgreen !important;
    text-align: center;
}

.expedient-header {
    background-color: lightyellow !important;
    text-align: center;
    position: sticky;
    left: 0;
    z-index: 1;
}

.input-date {
    width: 50% !important;
}

.label-checkbox {
    display: flex;
    flex-direction: column;
}

.input-checkbox {
    margin-left: 10px !important;
}
