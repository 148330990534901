.extra-row {
    display: flex;
    align-items: center;
}

.extra-input {
    margin-right: 5px;
}

.extra-price-input {
    width: 25%;
}
