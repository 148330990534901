.pagination-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.pagination-info {
    display: flex;
    align-items: center;
}

.pagination-info span {
    margin-right: 10px;
}

.pagination-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
}
