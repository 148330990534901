body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f8f9fa;
    color: #212529;
}

/* Navbar Styling */
#appNavbar {
    background-color: #004080; /* Professional dark blue */
    border-bottom: 2px solid #e67e22; /* Subtle orange accent */
    padding: 0.5rem 1rem;
}

#appNavbar .navbar-brand {
    color: #ffffff !important;
    font-weight: 600;
    font-size: 1.5rem;
}

#appNavbar .navbar-toggler {
    border-color: #ffffff;
}

#appNavbar .menu-icon {
    color: #ffffff;
}

#appNavbar .nav-link {
    color: #ffffff !important;
    margin-right: 1rem;
}

#appNavbar .nav-link:hover {
    text-decoration: underline;
    color: #e67e22 !important;
}

/* Dropdown Menu */
#appNavbar .dropdown-menu {
    background-color: #004080;
    border: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

#appNavbar .dropdown-item {
    color: #ffffff !important;
    padding: 0.5rem 1rem;
}

#appNavbar .dropdown-item:hover {
    background-color: #e67e22;
    color: #ffffff !important;
}

/* Button Styling */
button {
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
}

button.btn-primary {
    background-color: #004080;
    border-color: #004080;
}

button.btn-primary:hover {
    background-color: #e67e22;
    border-color: #e67e22;
}

/* Table Styling */
.table-hover tbody tr:hover {
    background-color: #f1f1f1;
}

.table th {
    background-color: #004080;
    color: #ffffff;
    font-weight: bold;
}

.table td {
    vertical-align: middle;
}

/* Form Inputs */
input, select {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0.375rem 0.75rem;
}

input:focus, select:focus {
    border-color: #e67e22;
    outline: none;
    box-shadow: 0 0 4px rgba(230, 126, 34, 0.5);
}

/* Checkbox & Labels */
label {
    font-size: 0.9rem;
    margin-left: 0.5rem;
    color: #212529;
}

input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin-right: 0.5rem;
}
