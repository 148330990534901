.centered-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.menu-icon {
    font-size: 1.5rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#appNavbar .dropdown {
    margin-right: 10px;
}

.navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: margin-left 0.3s ease; /* Smooth transition */
}
